import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>


    <p>{`Many people I meet say: "bitcoin is for criminals" -- maybe because they've heard that it has been used to buy drugs on deep-web services such as Silkroad. Just like criminals use Danish Kroner or U.S. Dollars to trade illegal things with.`}</p>
    <p>{`Heres' some wise reasons why I believe the title of this post couldn't be further away from the truth.`}</p>
    <ul>
      <li parentName="ul">{`Of all the currencies that criminals have preferences for the U.S. Dollar is king.`}</li>
      <li parentName="ul">{`It is way more difficult to do money-laundering with Bitcoin compared to e.g. U.S. Dollar`}</li>
      <li parentName="ul">{`Bitcoin is a tool to safe humanity from tyrans and oligarchy (the real criminals), today just covered up as a "get rich scheme"`}</li>
      <li parentName="ul">{`Mafia clans in random countries such as Russia, Venezuela, Zimbabwe and so on uses U.S. Dollars`}</li>
      <li parentName="ul">{`Bitcoin, per the specifications today, is probably the only monetary system that is most anti-criminal, given how open it is.`}</li>
      <li parentName="ul">{`"Yeah but cryptocurrencies are anonymous and that enables criminal behaviour", she said, handing a €50 bill over the counter at Starbucks that only hours before was used to buy meth cut with knock-off ritalin from a dirty cop outside an underage nightclub"`}</li>
    </ul>
    <h2>{`Data, data, data...`}</h2>
    <p>{`Given we have data on everything when using Bitcoin, in the case that we need to investigate a serious crime, we have very good data analysts and tools, to solve the issue, of even the most complex money laundering going on.`}</p>
    <p>{`A crime done with cash would be untraceable. One in bitcoin would not.`}</p>
    <p>{`For further reading, have a look at `}<a parentName="p" {...{
        "href": "http://www.sciencemag.org/news/2016/03/why-criminals-cant-hide-behind-bitcoin"
      }}>{`Why Criminals Can't Hide Behind Bitcoin`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      